<template>
    <DxToolbar style="height: 56px" class="toolbar">
        <DxItem location="before" locate-in-menu="never">
            <template #default>
                <div class="row align-center">
                    <DxButton
                        type="normal"
                        styling-mode="text"
                        icon="mdi mdi-chevron-left"
                        class="mr-2"
                        @click="$router.go(-1)"
                    />
                    <routerVue :breadcrumbsItems="breadcrumbsItems" />
                </div>
            </template>
        </DxItem>
    </DxToolbar>

    <div class="row justify-end align-center my-3 px-2">
        <DxButton
            icon="mdi mdi-download-outline"
            text="XML"
            type="default"
            @click="taiFileXML(ChiTietLenh)"
        />
        <DxButton
            icon="mdi mdi-download-outline"
            text="PDF"
            class="ml-3"
            type="default"
            @click="taiFilePDF(ChiTietLenh)"
        />
    </div>
    <div class="color-warning mb-2 px-2" v-if="isSafari">
        Không hỗ trợ TỰ ĐỘNG TẢI FILE PDF trên trình duyệt SAFARI! Nếu bạn muốn tải file
        PDF trên SAFARI vui lòng
        <span class="text-link" @click="XemHuongDanTaiPDF"> xem hướng dẫn tại đây! </span>
    </div>
    <div
        class="frame-gallery"
        :style="`height: calc(100vh - ${isSafari ? `244px` : `124px`})`"
    >
        <div
            class="row align-center justify-center"
            style="height: 100%"
            v-if="!ChiTietLenh.qrCode"
        >
            Không có bản thể hiện
        </div>

        <embed :src="ChiTietLenh.qrCode" width="100%" height="100%" v-else />
    </div>
</template>

<script>
import { DxButton } from "devextreme-vue";
import { DxToolbar, DxItem } from "devextreme-vue/toolbar";
import routerVue from "@sonphat/common-v1/components/router.vue";
export default {
    layout: "application",
    components: {
        DxButton,
        DxToolbar,
        DxItem,
        routerVue,
    },
    data() {
        return {
            row: "row align-center row-text mt-2 mb-2",
            rowTitle: "row  font-bold font-16 row-text mt-3",
            textLeft: "xs5 font-14 font-regular",
            textRight: "xs7 font-14 pl-3 font-medium text-xs-right",
            breadcrumbsItems: [
                {
                    id: "breadcrums_TraCuuLenh",
                    text: "Bản thể hiện lệnh",
                    disabled: true,
                    to: "",
                },
            ],
        };
    },
    computed: {
        screenWidth() {
            return this.$store.state.ManHinh.screenWidth;
        },
        ChiTietLenh() {
            return this.$store.state.TraCuuLenh.ChiTietLenh;
        },
        isSafari() {
            let check = false;
            var ua = navigator.userAgent.toLowerCase();
            if (ua.indexOf("safari") != -1) {
                if (ua.indexOf("chrome") > -1) {
                    // Chrome
                } else {
                    check = true;
                }
            }
            return check;
        },
    },
    methods: {
        XemHuongDanTaiPDF() {
            const routeData = this.$router.resolve({
                path: "/Huong-Dan-Tai-Lenh-Iphone",
            });
            window.open(window.location.origin + routeData.href, "_blank");
        },
        async taiFilePDF(itemData) {
            if (itemData.qrCode) {
                let a = document.createElement("a");
                a.href =
                    itemData.qrCode +
                    `?isAttach=true&fileName={${itemData.bienKiemSoat}}_{${itemData.maLenh}}_{${itemData.maTuyen}}.pdf`;
                a.textContent = "Tada";
                a.download = `{${itemData.bienKiemSoat}}_{${itemData.maLenh}}_{${itemData.maTuyen}}.pdf`;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            }
        },
        async taiFileXML(itemData) {
            if (itemData.linkTaiXml) {
                let result = await this.$Core.Api.LenhVanChuyen(
                    itemData.linkTaiXml
                ).Get();

                if (result.Data.status) {
                    const xmlData = result.Data.data;

                    const blob = new Blob([xmlData], { type: "application/xml" });

                    const url = URL.createObjectURL(blob);

                    const a = document.createElement("a");
                    a.href = url;
                    a.download = `{${itemData.bienKiemSoat}}_{${itemData.maLenh}}_{${itemData.maTuyen}}.xml`;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);

                    URL.revokeObjectURL(url);
                }
            }
        },
    },

    created() {},
};
</script>

<style scoped>
.row-text {
    border-bottom: 1px dashed #ccc;
}
</style>
